import { useTheme } from '@mui/material/styles';
import '@vivino/js-react-common-ui';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface DeviceBreakpoint {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface DeviceBreakpointProviderProps {
  children: React.ReactNode;
  isMobile: boolean;
  isTablet: boolean;
}

export const DeviceBreakpointContext = createContext<DeviceBreakpoint>({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
});

export const DeviceBreakpointProvider = ({
  children,
  isMobile,
  isTablet,
}: DeviceBreakpointProviderProps) => {
  const [isMobileState, setIsMobileState] = useState(isMobile);
  const [isSmallerThanDesktop, setIsSmallerThanDesktop] = useState(isTablet);
  const { breakpoints } = useTheme();

  const MOBILE_MEDIA_QUERY = `(max-width: ${breakpoints.values.sm - 1}px)`;
  const SMALLER_THAN_DESKTOP_QUERY = `(max-width: ${breakpoints.values.lg - 1}px)`;

  useEffect(() => {
    const mobileQuery = globalThis.matchMedia(MOBILE_MEDIA_QUERY);
    const tabletQuery = globalThis.matchMedia(SMALLER_THAN_DESKTOP_QUERY);
    const mobileQueryListener = (e: MediaQueryListEvent) => updateMediaState(e, setIsMobileState);
    const tabletQueryListener = (e: MediaQueryListEvent) =>
      updateMediaState(e, setIsSmallerThanDesktop);
    mobileQuery.addEventListener('change', mobileQueryListener);
    tabletQuery.addEventListener('change', tabletQueryListener);

    setIsMobileState(mobileQuery.matches);
    setIsSmallerThanDesktop(tabletQuery.matches);

    return () => {
      mobileQuery.removeEventListener('change', mobileQueryListener);
      tabletQuery.removeEventListener('change', tabletQueryListener);
    };
  }, []);

  const updateMediaState = (e: MediaQueryListEvent, setter: Dispatch<SetStateAction<boolean>>) => {
    if (e.matches) {
      setter(true);
    } else {
      setter(false);
    }
  };

  const values = {
    isMobile: isMobileState,
    isTablet: !isMobile && isSmallerThanDesktop,
    isDesktop: !isMobile && !isSmallerThanDesktop,
  };

  return (
    <DeviceBreakpointContext.Provider value={values}>{children}</DeviceBreakpointContext.Provider>
  );
};

export const useBreakpoint = () => {
  const context = useContext(DeviceBreakpointContext);
  if (context === undefined) {
    throw new Error('useBreakpoint must be used within a DeviceBreakpointProvider');
  }
  return context;
};
