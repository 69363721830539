import { ComponentSizes } from '@vivino/js-web-common';
import cx from 'classnames';
import React from 'react';

import styles from './truncatedText.module.scss';

export enum NumTruncateLines {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
}

interface TruncatedTextProps {
  numLines?: number;
  size?: ComponentSizes;
  className?: string;
  dataTestId?: string;
  children: React.ReactNode;
}

export default function TruncatedText({
  numLines = NumTruncateLines.Three,
  size = ComponentSizes.Large,
  className,
  children,
}: TruncatedTextProps) {
  return (
    <div
      className={cx(
        styles.truncatedText,
        {
          [styles.large]: size === ComponentSizes.Large,
        },
        styles[`truncatedText-${numLines}`],
        className
      )}
    >
      {children}
    </div>
  );
}
