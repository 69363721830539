import React from 'react';

import styles from './partnerWineryBadge.module.scss';

export enum PartnerWineryBadgeSizes {
  small = 'small',
  normal = 'normal',
}

interface PartnerWineryBadgeProps {
  size?: PartnerWineryBadgeSizes;
}

function PartnerWineryBadge({ size = PartnerWineryBadgeSizes.normal }: PartnerWineryBadgeProps) {
  if (size === PartnerWineryBadgeSizes.small) {
    return (
      <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.575 14.46C6.207 13.764 2 11.35 2 8V5.386c0-.502 0-.753.079-.972.07-.193.183-.368.332-.51.168-.162.397-.264.856-.467l4.3-1.911c.16-.071.24-.107.322-.12a.667.667 0 0 1 .222 0c.083.013.163.049.322.12l4.3 1.91c.459.204.688.306.856.467.149.143.262.318.332.511.079.22.079.47.079.972V8c0 3.35-4.207 5.764-5.575 6.46-.138.07-.207.106-.3.123a.753.753 0 0 1-.25 0c-.093-.017-.162-.053-.3-.123Zm3.23-7.323a.667.667 0 1 0-.943-.942L7.333 8.723l-.862-.862a.667.667 0 1 0-.942.943l1.333 1.333c.26.26.682.26.943 0l3-3Z"
          fill="url(#a)"
        />
        <defs>
          <linearGradient
            id="a"
            x1=".333"
            y1=".334"
            x2="10"
            y2="13.667"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EE803E" />
            <stop offset="1" stopColor="#BA1628" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className={styles.partnerBadge}
    >
      <path
        d="M11.363 21.691c.207.105.31.158.45.184.11.021.264.021.374 0 .14-.026.243-.079.45-.184C14.69 20.646 21 17.024 21 12V8.08c0-.753 0-1.13-.118-1.458a2 2 0 0 0-.498-.766c-.253-.242-.596-.395-1.284-.7l-6.45-2.867c-.24-.107-.36-.16-.484-.18a1 1 0 0 0-.332 0c-.125.02-.245.073-.484.18L4.9 5.155c-.688.306-1.031.459-1.284.7a2 2 0 0 0-.498.767C3 6.95 3 7.327 3 8.08V12c0 5.024 6.31 8.646 8.363 9.691Z"
        fill="url(#a)"
      />
      <path
        d="m9 12.5 2 2 4.5-4.5"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="a" x1=".5" y1=".501" x2="15" y2="20.501" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE803E" />
          <stop offset="1" stopColor="#BA1628" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PartnerWineryBadge;
