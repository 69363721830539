import { AnyWinery } from '@webtypes/rubyLibApi';

const WineryStatuses = {
  Unclaimed: 0,
  Claimed: 1,
  Pro: 2,
  Sponsor: 3,
};

export const isPartnerWinery = (winery: AnyWinery) => winery?.status === WineryStatuses.Sponsor;
