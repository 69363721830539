import cx from 'classnames';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import Range from 'rc-slider/lib/Range';
import React, { useEffect, useState } from 'react';

import styles from './rangeSlider.scss';
import { getSliderPosition, getSliderValue } from './sliderScale';

const RangeSlider = ({
  isSimple,
  initialValues,
  defaultMax,
  range,
  labelPrefix,
  labelSuffix,
  title,
  onChange,
  onAfterChange,
  onFormattedRangeChange,
}) => {
  const [minimum, setMinimum] = useState(initialValues.minimum);
  const [maximum, setMaximum] = useState(initialValues.maximum);

  const getFormattedRangeText = (min, max) => {
    return `${labelPrefix || ''}${min}${labelSuffix || ''} – ${labelPrefix || ''}${max}${
      labelSuffix || ''
    }${max === range.maximum ? '+' : ''}`;
  };

  useEffect(() => {
    if (onFormattedRangeChange) {
      onFormattedRangeChange(getFormattedRangeText(minimum, maximum));
    }
  }, [minimum, maximum, labelPrefix, labelSuffix, range.maximum, onFormattedRangeChange]);

  function handleRangeChange([minPosition, maxPosition]) {
    const minimum = isSimple ? minPosition : getValue(minPosition);
    const maximum = isSimple ? maxPosition : getValue(maxPosition);
    if (onChange) {
      onChange([minimum, maximum]);
    }
    setMaximum(maximum);
    setMinimum(minimum);
  }

  function handleAfterRangeChange([minPosition, maxPosition]) {
    const minimum = isSimple ? minPosition : getValue(minPosition);
    const maximum = isSimple ? maxPosition : getValue(maxPosition);
    if (onAfterChange) {
      onAfterChange([minimum, maximum]);
    }
  }

  function getValue(position) {
    return getSliderValue({
      position,
      defaultMax,
      rangeMax: range.maximum,
    });
  }

  function getPosition(value) {
    return getSliderPosition({
      value,
      defaultMax,
      rangeMax: range.maximum,
    });
  }
  const rangeBgColor = '#575757';
  const handleStyle = {
    width: '24px',
    height: '24px',
    backgroundColor: '#FFFFFF',
    border: '8px solid #1E1E1E',
    top: '2px',
    transform: 'translate(-3px, 0)',
  };

  const rangeProps = {
    trackStyle: [{ backgroundColor: rangeBgColor }],
    handleStyle: [handleStyle, handleStyle],
    onChange: handleRangeChange,
    onAfterChange: handleAfterRangeChange,
  };
  if (isSimple) {
    rangeProps.steps = 0.5;
    rangeProps.min = range.minimum;
    rangeProps.max = range.maximum;
    rangeProps.defaultValue = [minimum, maximum];
  } else {
    rangeProps.min = getPosition(range.minimum);
    rangeProps.max = getPosition(range.maximum);
    rangeProps.defaultValue = [getPosition(minimum), getPosition(maximum)];
  }

  return (
    <div className={styles.rangeSlider}>
      <div className={styles.header}>
        <div className={cx(styles.label, styles.left)}>
          {getFormattedRangeText(minimum, maximum)}
        </div>
        {title && <div className={styles.label}>{title}</div>}
      </div>
      <div className={styles.slider}>
        <Range {...rangeProps} />
      </div>
    </div>
  );
};
RangeSlider.defaultProps = {
  labelPrefix: null,
  labelSuffix: null,
  isSimple: false,
};

RangeSlider.propTypes = {
  isSimple: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  defaultMax: PropTypes.number.isRequired,
  range: PropTypes.object.isRequired,
  // Optional props
  labelPrefix: PropTypes.string,
  labelSuffix: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  onFormattedRangeChange: PropTypes.func,
};

export default RangeSlider;
