import { DeferredImage } from '@vivino/js-react-common-ui';
import { Image, imagesUri, useI18N } from '@vivino/js-web-common';
import cx from 'classnames';
import React from 'react';

import styles from './bottleShot.scss';

const BOTTLE_SHOT_IMAGE_PATHS = 'bottleShot';

const TRANSLATIONS = {
  wineImage: 'components.shared.bottle_shot.wine_image',
};

enum BottleTypeId {
  Red = 1,
  White = 2,
  Fortified = 24,
  Sparkling = 3,
  Rose = 4,
  Desert = 7,
}

export enum BottleSize {
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const fallBackBottleShotUrl = (typeId: BottleTypeId) =>
  imagesUri(`${BOTTLE_SHOT_IMAGE_PATHS}/fallback_${typeId}.png`);

export const wineTypeFallback = (typeId: BottleTypeId) => {
  switch (typeId) {
    case BottleTypeId.Red:
    case BottleTypeId.White:
    case BottleTypeId.Fortified:
    case BottleTypeId.Sparkling:
    case BottleTypeId.Desert:
    case BottleTypeId.Rose:
      return fallBackBottleShotUrl(typeId);
    default:
      return fallBackBottleShotUrl(BottleTypeId.Red);
  }
};

export const calculateBottleImageUrl = (image, size, typeId) => {
  const fallbackImage = wineTypeFallback(typeId);

  const imageVariations = image?.variations;
  let imageSrc;
  switch (size) {
    case BottleSize.Small:
    case BottleSize.Tiny:
      imageSrc = imageVariations?.bottle_small;
      break;
    case BottleSize.Large:
      imageSrc = imageVariations?.bottle_large;
      break;
    default:
      imageSrc = imageVariations?.bottle_medium;
  }
  return imageSrc || fallbackImage;
};

interface BottleShotProps {
  image?: Image;
  size?: BottleSize;
  className?: string;
  typeId?: number;
  alt?: string | any[];
  scale?: boolean;
}

const BottleShot = ({
  alt,
  image,
  className,
  typeId = 1,
  scale = false,
  size = BottleSize.Medium,
}: BottleShotProps) => {
  const { t } = useI18N();
  const ariaLabel = alt || t(TRANSLATIONS.wineImage);
  return (
    <DeferredImage src={calculateBottleImageUrl(image, size, typeId)}>
      <div
        role="img"
        aria-label={ariaLabel as string}
        className={cx(styles[`wine-type${typeId || 1}`], styles.bottleShot, className, {
          [styles[size]]: !scale,
        })}
      />
    </DeferredImage>
  );
};

export default BottleShot;
