import {
  CouponTrackingEvents,
  getMerchandizingCouponItem,
  removeMerchandizingCouponItem,
  setMerchandizingCouponItem,
} from '@vivino/js-web-common';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { trackEvent } from 'vivino-js/analytics';
import { CartContext } from 'vivino-js/context/CartContext';
import { useCartContextLegacy } from 'vivino-js/context/CartContextLegacy';
import { getValidCartsInCurrentCountry } from 'vivino-js/helpers/cart';
import {
  CampaignTrackingProps,
  MerchandizingCoupon,
  getPreloadedCampaignTrackingInfo,
} from 'vivino-js/helpers/merchandizingHelper';

interface CouponContextReturnType {
  isCouponApplied: boolean;
  storedCouponItem: MerchandizingCoupon;
  applyCouponToCart: () => Promise<any>;
  activateCoupon: (couponInformation: MerchandizingCoupon) => void;
  trackCouponEvent: (event: CouponTrackingEvents, props?: TrackingProps) => void;
  deleteStoredCoupon: () => void;
  checkIfCouponIsApplicable: () => Promise<{
    isCouponAutoApplicable: boolean;
    isCouponManuallyApplicable: boolean;
  }>;
  preloadedCampaignInfo: CampaignTrackingProps;
  isFallbackReminderVisible: boolean;
  displayFallbackReminder: () => void;
}

export const CouponContext = createContext<Partial<CouponContextReturnType>>({});

export interface CouponContextProps {
  page?: CouponContextPage;
  children?: ReactNode;
}

export enum CouponContextPage {
  CartPage = 'CartPage',
  Checkout = 'Checkout',
}

interface TrackingProps {
  campaign_type?: string;
  campaign_id?: number;
  campaign_slug?: string;
  campaign_title?: string;
  voucher_code?: string;
  action?: string;
}

const getMerchandizingCouponWhenValid = () => {
  const couponItem = getMerchandizingCouponItem();
  if (couponItem && isCouponExpired(couponItem)) {
    return null;
  }
  return couponItem;
};

const isCouponExpired = (couponItem: MerchandizingCoupon): boolean => {
  const couponExpirationDate = new Date(couponItem?.couponExpiryDate);
  const currentDate = new Date();
  return couponExpirationDate < currentDate;
};

export const CouponProvider = ({ page, children }: CouponContextProps) => {
  const [storedCouponItem, setStoredCouponItem] = useState<MerchandizingCoupon>(
    getMerchandizingCouponWhenValid()
  );
  const [shouldShowFallbackReminderState, setShouldShowFallbackReminderState] = useState(false);

  const preloadedCampaignInfo = getPreloadedCampaignTrackingInfo();

  const couponCode = storedCouponItem?.couponCode;
  const isCouponApplied = !!storedCouponItem?.isApplied;

  const commonTrackingProps: TrackingProps = {
    campaign_type: storedCouponItem?.campaignType,
    campaign_id: storedCouponItem?.campaignId,
    campaign_slug: storedCouponItem?.campaignSlug,
    campaign_title: storedCouponItem?.campaignTitle,
    voucher_code: storedCouponItem?.couponCode,
  };

  // since cart page and checkout we need to use two different cart contexts
  // this context will decide which one to call based on page parameter
  // @ts-ignore
  const { onUpdateCart, cart } = useCartContextLegacy();
  // checkout context
  const { updateCartContext } = useContext(CartContext);

  useEffect(() => {
    if (storedCouponItem) {
      setMerchandizingCouponItem(storedCouponItem);
    } else {
      removeMerchandizingCouponItem();
    }
  }, [storedCouponItem]);

  const handleCartUpdateSuccess = () => {
    setStoredCouponItem({ ...storedCouponItem, isApplied: true });
  };

  const handleCartUpdateError = () => {
    setShouldShowFallbackReminderState(true);
  };

  const updateCart = () => {
    if (page === CouponContextPage.CartPage) {
      /* must send the shipping fields along with coupon code
       * in cart page because a PUT with only coupon_code will remove the
       * shipping zip from the cart, which in zip-based countries will render
       * the start checkout button disabled!!
       */
      const shippingFields = {
        shipping_country: cart?.shipping_country,
        shipping_zip: cart?.shipping_zip,
        shipping_state: cart?.shipping_state,
      };

      const body = {
        ...shippingFields,
        coupon_code: couponCode,
      };
      return onUpdateCart({
        body,
      })
        .then(() => {
          handleCartUpdateSuccess();
        })
        .catch(() => {
          handleCartUpdateError();
        });
    } else if (CouponContextPage.Checkout) {
      return updateCartContext({
        coupon_code: couponCode,
      }).then(() => {
        handleCartUpdateSuccess();
      });
    }
  };

  const checkIfCouponIsApplicable = async (): Promise<{
    isCouponAutoApplicable: boolean;
    isCouponManuallyApplicable: boolean;
  }> => {
    let isCouponAutoApplicable = false;
    let isCouponManuallyApplicable = false;

    if (!storedCouponItem || isCouponApplied) {
      return { isCouponAutoApplicable, isCouponManuallyApplicable };
    }

    try {
      const validCartsInCurrentCountry = await getValidCartsInCurrentCountry();

      const numberOfValidCartsInCurrentCountry = validCartsInCurrentCountry.length;

      isCouponAutoApplicable = numberOfValidCartsInCurrentCountry === 1;
      isCouponManuallyApplicable = numberOfValidCartsInCurrentCountry > 1;
    } catch {
      isCouponAutoApplicable = false;
      isCouponManuallyApplicable = false;
    }

    return { isCouponAutoApplicable, isCouponManuallyApplicable };
  };

  const applyCouponToCart = (): Promise<any> => {
    if (shouldShowFallbackReminderState) {
      setShouldShowFallbackReminderState(false);
    }

    return updateCart();
  };

  const activateCoupon = (couponInformation) => {
    setStoredCouponItem({ ...couponInformation, isApplied: false });
  };

  const deleteStoredCoupon = () => {
    setStoredCouponItem(null);
  };

  const displayFallbackReminder = () => {
    setShouldShowFallbackReminderState(true);
  };

  const trackCouponEvent = (event: CouponTrackingEvents, props: TrackingProps) => {
    const trackingProps = {
      ...commonTrackingProps,
      ...props,
    };

    // heap
    trackEvent({ event, props: trackingProps });
  };

  return (
    <CouponContext.Provider
      value={{
        isCouponApplied,
        storedCouponItem,
        preloadedCampaignInfo,
        activateCoupon,
        applyCouponToCart,
        deleteStoredCoupon,
        checkIfCouponIsApplicable,
        trackCouponEvent,
        isFallbackReminderVisible: shouldShowFallbackReminderState,
        displayFallbackReminder,
      }}
    >
      {children}
    </CouponContext.Provider>
  );
};

export const CouponConsumer = CouponContext.Consumer;
